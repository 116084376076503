import type { PropsWithTheme } from "@/theme";

export type MenuElement = HTMLDivElement;

export enum MenuDirection {
	vertical = "vertical",
	horizontal = "horizontal",
}

export interface StyledMenuProps extends PropsWithTheme<MenuElement> {
	direction?: MenuDirection;
}
