export const COUNTRY_CAPITAL_COORDINATES = {
	de: { lat: 52.52, lng: 13.405 },
	es: { lat: 40.4168, lng: -3.7038 },
};

export const ZOOM_LEVEL = {
	veryLow: 15,
	low: 14,
	moderate: 12,
	high: 10,
	extreme: 7,
};

export const MAX_GMAPS_ZOOM = 21;

/**
 * The map id is responsible for setting the visual style of the Google Map
 * Managed at https://console.cloud.google.com/google/maps-apis/studio/styles
 */
export const GOOGLE_MAP_ID = "f19e3ead3abf79b0";
