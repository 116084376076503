import { NavigationLinkType } from "@/components/layout/types";
import { COUNTRY_CAPITAL_COORDINATES, ZOOM_LEVEL } from "@/constants/maps";
import { YOUTUBE_EMBED_URL } from "@/constants/urls";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import type {
	Location,
	NavigationItem,
	NavigationItemInternalEntryLink,
	SingleLinkEntryLink,
} from "@/types/contentful-api";
import type { VideoProps } from "@/types/video";
import { VideoType } from "@/types/video";

export const getVideoLink = ({ videoId, type }: VideoProps): string => {
	switch (type) {
		case VideoType.youtube:
			return `${YOUTUBE_EMBED_URL}/${videoId}`;
		default:
			return "";
	}
};

export const urlWithQuery = (url: string) => {
	if (typeof window === "undefined") {
		return url;
	}

	return `${url}${window.location.search}`;
};

export type LinkConfig = { href: string | null; as: string | null };

const locationOrFallback = (location: Location, locale: string) => {
	if (location?.lat && location?.lon) {
		return { lat: location.lat, lng: location.lon };
	}

	return COUNTRY_CAPITAL_COORDINATES[locale];
};

export const getLinkFromEntryLink = (
	entry: NavigationItemInternalEntryLink | SingleLinkEntryLink,
	locale: Locale,
	navigationLinkType?: NavigationLinkType
): LinkConfig => {
	switch (entry?.__typename) {
		case "GuideCategory":
			return {
				href: `/${dirs.guide.href}/${entry.slug}/`,
				as: `/${dirs.guide.dir[locale]}/${entry.slug}/`,
			};
		case "Headquarter":
			return {
				href: `/${dirs.ourAgents.href}/${entry.slug}/`,
				as: `/${dirs.ourAgents.dir[locale]}/${entry.slug}/`,
			};
		case "City":
			if (navigationLinkType === NavigationLinkType.buy) {
				const { lat: cityLat, lng: cityLng } = locationOrFallback(entry.location, locale);
				const zoomLevel = entry?.zoomLevel ?? ZOOM_LEVEL.high;

				return {
					href: `/${dirs.search.href}/?lat=${cityLat}&lng=${cityLng}&zoom=${zoomLevel}`,
					as: `/${dirs.search.dir[locale]}/?lat=${cityLat}&lng=${cityLng}&zoom=${zoomLevel}`,
				};
			}

			return {
				href: `/${dirs.living.href}/${entry.country}/${entry.state}/${entry.city}/`,
				as: `/${dirs.living.dir[locale]}/${entry.country}/${entry.state}/${entry.city}/`,
			};
		case "Country":
			const { lat: countryLat, lng: countryLng } = locationOrFallback(entry.location, locale);

			return {
				href: `/${dirs.search.href}/?lat=${countryLat}&lng=${countryLng}&zoom=${ZOOM_LEVEL.extreme}`,
				as: `/${dirs.search.dir[locale]}/?lat=${countryLat}&lng=${countryLng}&zoom=${ZOOM_LEVEL.extreme}`,
			};
		case "Region":
			const { lat: regionLat, lng: regionLng } = locationOrFallback(entry.location, locale);
			const zoomLevel = entry?.zoomLevel ?? ZOOM_LEVEL.high;

			return {
				href: `/${dirs.search.href}/?lat=${regionLat}&lng=${regionLng}&zoom=${zoomLevel}`,
				as: `/${dirs.search.dir[locale]}/?lat=${regionLat}&lng=${regionLng}&zoom=${zoomLevel}`,
			};
		case "District":
			if (navigationLinkType === NavigationLinkType.buy) {
				const location = {
					lat: entry?.geometry?.location?.lat,
					lon: entry?.geometry?.location?.lng,
				};
				const { lat, lng } = locationOrFallback(location, locale);
				const zoomLevel = entry?.zoomLevel ?? ZOOM_LEVEL.low;

				return {
					href: `/${dirs.search.href}/?lat=${lat}&lng=${lng}&zoom=${zoomLevel}`,
					as: `/${dirs.search.dir[locale]}/?lat=${lat}&lng=${lng}&zoom=${zoomLevel}`,
				};
			}

			return {
				href: `/${dirs.living.href}/${entry.country}/${entry.state}/${entry.city}/${entry.district}/`,
				as: `/${dirs.living.dir[locale]}/${entry.country}/${entry.state}/${entry.city}/${entry.district}/`,
			};
		case "SubPage":
			if (entry.dir === "guide") {
				return {
					href: `/${dirs.guide.href}/${entry.category.slug}/${entry.slug}/`,
					as: `/${dirs.guide.dir[locale]}/${entry.category.slug}/${entry.slug}/`,
				};
			}

			// Listings map page
			if (entry.dir === "search") {
				return {
					href: `/${dirs.buy.href}/${dirs.search.href}/`,
					as: `/${dirs.buy.dir[locale]}/${dirs.search.dir[locale]}/`,
				};
			}

			return {
				href: `/${dirs[entry.dir].href as string}/${entry.slug}/`,
				as: `/${dirs[entry.dir].dir[locale] as string}/${entry.slug}/`,
			};
		case "Page":
			return {
				href: `/${dirs[entry.dir].href as string}/`.replace(/\/\//g, "/"),
				as: `/${dirs[entry.dir].dir[locale] as string}/`.replace(/\/\//g, "/"),
			};
		case "Person":
			if (entry.jobDepartment === "realEstate") {
				return {
					href: `/${dirs.ourAgents.href}/${entry.headquarter.slug}/${entry.slug}/`,
					as: `/${dirs.ourAgents.dir[locale]}/${entry.headquarter.slug}/${entry.slug}/`,
				};
			}

			return { href: null, as: null };
		case "Property":
			return {
				href: `/${dirs.property.href}/${entry.sys.id}/`,
				as: `/${dirs.property.dir[locale]}/${entry.sys.id}/`,
			};
		default:
			return { href: null, as: null };
	}
};

export const getURL = (menuItem: NavigationItem, locale: string): LinkConfig => {
	const { internalEntryLink, externalUrl } = menuItem;

	// Linking to an internal page-like content-type
	if (internalEntryLink) {
		return getLinkFromEntryLink(
			internalEntryLink,
			locale as Locale,
			menuItem.navigationLinkType as NavigationLinkType
		);
	}

	// External link
	if (externalUrl) {
		return { href: externalUrl, as: null };
	}

	// Link was omitted in Contentful
	return { href: null, as: null };
};
