import React, { forwardRef, useEffect, useState } from "react";

import { useWindowScroll } from "react-use";

import { toOpacityValue } from "@/utils/number";

import {
	StyledHeader,
	StyledHeaderContainer,
	StyledHeaderBackground,
	StyledHeaderContent,
} from "./components";
import type { HeaderElement, StyledHeaderProps } from "./types";

export const Header = forwardRef<HeaderElement, StyledHeaderProps>(
	({ children, translucent, fadeOffset = 0, ...props }, ref) => {
		const [opacity, setOpacity] = useState(translucent ? 0 : 1);
		const { y } = useWindowScroll();

		useEffect(() => {
			setOpacity(toOpacityValue(translucent ? y : fadeOffset, fadeOffset));
		}, [translucent, y, fadeOffset]);

		return (
			<StyledHeader {...props} ref={ref}>
				<StyledHeaderContainer fixed={props.fixed}>
					<StyledHeaderBackground {...props} style={{ opacity }} />
					<StyledHeaderContent {...props}>{children}</StyledHeaderContent>
				</StyledHeaderContainer>
			</StyledHeader>
		);
	}
);
