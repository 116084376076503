import React from "react";

import { getId } from "@/utils/id";

import type { HeadlineProps } from "./types";

export const BreakLines: React.FC<HeadlineProps> = ({ text }) => {
	// Handling null values from Contentful (added during localisation refactor)
	if (typeof text !== "string") {
		return null;
	}

	return (
		<>
			{text.split("\n").map((line, i, lines) => (
				<React.Fragment key={getId(i)}>
					{line}
					{i < lines.length - 1 && <br />}
				</React.Fragment>
			))}
		</>
	);
};
