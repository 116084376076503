import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { MenuElement, StyledMenuProps } from "./types";
import { MenuDirection } from "./types";

const StyledMenu = styled.nav<StyledMenuProps>`
	display: flex;
	${({ direction }) => css`
		flex-direction: ${direction === MenuDirection.vertical ? "column" : "row"};
	`};
`;

export const Menu = React.forwardRef<MenuElement, StyledMenuProps>((props, ref) => (
	<StyledMenu {...props} ref={ref} />
));
