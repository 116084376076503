import React from "react";

import { Trans } from "next-i18next";

import { useRouter } from "next/router";

import type { TransdownProps } from "@/components/i18n/types";
import { StyledGreenText } from "@/components/valuation/components";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";

export const Transdown: React.FC<TransdownProps> = ({ i18nKey, values = {} }) => {
	const { locale } = useRouter();
	return (
		<Trans
			i18nKey={i18nKey}
			values={values}
			components={{
				privacyPolicy: (
					/* eslint-disable-next-line jsx-a11y/anchor-has-content */
					<a
						target="_blank"
						rel="noreferrer"
						href={`/${locale}/${dirs[Dirs.privacyPolicy].dir[locale] as string}/`}
					/>
				),
				highlightText: <StyledGreenText />,
			}}
		/>
	);
};
