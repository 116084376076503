import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const StyledProgressBar = styled.div<PropsWithTheme & { percentage: number }>`
	height: 8px;
	transition: width 0.3s ease-out;
	${({ theme: { palette }, percentage }) => css`
		width: ${percentage}%;
		background: ${palette.green[500]};
	`};
`;
