import React from "react";

import {
	bunTransformation,
	StyledBurgerWrapper,
	StyledBurgerBun,
	StyledToggle,
	HiddenText,
} from "./styles";
import type { StyledToggleProps, ToggleElement } from "./types";

const Burger: React.FC<StyledToggleProps> = ({ open }) => {
	const [topBun, setTopBun] = React.useState(bunTransformation.top[open ? "open" : "closed"]);
	const [bottomBun, setBottomBun] = React.useState(
		bunTransformation.bottom[open ? "open" : "closed"]
	);
	React.useEffect(() => {
		setTopBun(bunTransformation.top[open ? "open" : "closed"]);
		setBottomBun(bunTransformation.bottom[open ? "open" : "closed"]);
	}, [open]);
	return (
		<StyledBurgerWrapper>
			<StyledBurgerBun style={{ transform: topBun }} />
			<StyledBurgerBun style={{ transform: bottomBun }} />
		</StyledBurgerWrapper>
	);
};

export const Toggle = React.forwardRef<ToggleElement, StyledToggleProps>(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ children, open, ...props }, ref) => (
		<StyledToggle {...props} open={open} ref={ref}>
			<Burger open={open} />
			<HiddenText>{open ? "close" : "open"} Menu</HiddenText>
		</StyledToggle>
	)
);
