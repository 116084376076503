export enum AssetFit {
	pad = "pad",
	fill = "fill",
	scale = "scale",
	crop = "crop",
	thumb = "thumb",
}

export enum AssetFocus {
	center = "center",
	top = "top",
	right = "right",
	left = "left",
	bottom = "bottom",
	"top_right" = "top_right",
	"top_left" = "top_left",
	"bottom_right" = "bottom_right",
	"bottom_left" = "bottom_left",
	face = "face",
	faces = "faces",
}

export enum AssetFormat {
	jpg = "jpg",
	png = "png",
	webp = "webp",
}
