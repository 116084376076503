import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { SpacerProps } from "@/components/layout/types";

/***
 * xxxs: 8px;
 *
 * xxs: 16px;
 *
 * xs: 24px;
 *
 * s: 32px;
 *
 * m: 40px;
 *
 * l: 48px;
 *
 * xl: 64px;
 *
 * xxl: 80px;
 *
 * xxxl: 120px;
 * */
export const Spacer = styled.span<SpacerProps>`
	display: block;
	width: 100%;
	${({ spacing }) => css`
		height: var(--spacing-${spacing as string});
	`};
`;

export const StyledMain = styled.main<{ isIOSSafari?: boolean }>`
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - var(--header-height-ref));
	${({ isIOSSafari }) =>
		isIOSSafari
			? css`
					min-height: calc(
						100vh - var(--header-height) - 5rem - env(safe-area-inset-bottom)
					);
			  `
			: ""}
`;
