import type { PropsWithTheme, Spaces } from "@/theme";
import type {
	BusinessInfoCollection,
	Meta,
	NavigationCollection,
	NavigationItem,
} from "@/types/contentful-api";

export interface SpacerProps extends PropsWithTheme {
	spacing: keyof Spaces;
}

export interface NavigationComponentProps {
	open?: boolean;
	toggleOpen: () => void;
	data: {
		headerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	};
}

export interface HeaderProps extends NavigationComponentProps {
	translucentHeader?: boolean;
}

export enum NavigationType {
	sidebar = "sidebar",
	header = "header",
}

export interface ExpandableNavProps {
	menuItem: NavigationItem;
	type?: NavigationType;
}

export enum NavigationLinkType {
	buy = "buy",
	marketing = "marketing",
}

export interface FooterMenuProps {
	data: NavigationItem[];
}

export interface LayoutProps<T = unknown> {
	url?: string;
	data?: T;
	meta?: Meta;
	translucentHeader?: boolean;
}
