import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Link from "next/link";
import { useRouter } from "next/router";

import { Typography } from "@/components/typography/typography";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import type { NavigationItem } from "@/types/contentful-api";
import { getURL } from "@/utils/urls";

import { Menu, MenuDirection } from "../menu";
import type { FooterMenuProps } from "../types";

export const IconGrid = styled.div<PropsWithTheme>`
	display: grid;
	grid-gap: var(--spacing-xxs);
	grid-template-columns: repeat(auto-fill, var(--spacing-xs));
	margin: var(--spacing-s) 0 0;
`;

export const StyledFooter = styled.footer<PropsWithTheme>`
	padding: var(--spacing-xl) 0;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
	`};
`;

export const StyledFooterLink = styled.a<PropsWithTheme>`
	display: inline-flex;
	width: max-content;
	font-weight: ${FONT_WEIGHT.light};
	line-height: 28px;
	text-decoration: none;

	&:hover {
		${({ theme: { palette } }) => css`
			color: ${palette.blue[500]};
		`};
	}
`;

export const FooterCallEmailLink = styled(StyledFooterLink)`
	display: block;
`;

export const AccordionHeader = ({ children, ...props }) => (
	<Typography {...props} tight component="h4" variant={TypographyVariant.headlineSansXXS}>
		{children}
	</Typography>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FooterNavigationMenu: React.FC<FooterMenuProps> = ({ children, data, ...props }) => {
	const trackEvent = useTrackingEvent({
		"Event Category": "Navigation",
		"Event Action": "Click",
		"Event Label": "Footer Navigation Used",
	});

	const { locale } = useRouter();

	return (
		<Menu direction={MenuDirection.vertical} {...props}>
			{data
				.filter(menuItem => menuItem?.internalEntryLink || menuItem?.externalUrl)
				.map((menuItem: NavigationItem) => {
					const linkConfig = getURL(menuItem, locale);
					return (
						<Link
							key={menuItem.sys.id}
							passHref
							href={linkConfig.href}
							as={linkConfig.as}
							locale={locale}
							prefetch={false}
						>
							<StyledFooterLink onClick={trackEvent}>
								{menuItem.label}
							</StyledFooterLink>
						</Link>
					);
				})}
		</Menu>
	);
};
