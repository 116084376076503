import type { Dir } from "./types";
import { Dirs } from "./types";

const dirs: Record<Dirs, Dir> = {
	[Dirs.ROOT]: {
		breadcrumb: {
			de: "Home",
			es: "Home",
		},
		dir: {
			de: "",
			es: "",
		},
		href: "/",
	},
	[Dirs.ourAgents]: {
		breadcrumb: {
			es: "Nuestros Agentes",
			de: "Unsere Agenten",
		},
		dir: {
			es: "nuestros-agentes",
			de: "unsere-makler",
		},
		href: "our-agents",
	},
	[Dirs.guide]: {
		breadcrumb: {
			es: "Guia",
			de: "Ratgeber",
		},
		dir: {
			es: "guia",
			de: "ratgeber",
		},
		href: "guides",
	},
	[Dirs.press]: {
		breadcrumb: {
			es: "Prensa",
			de: "Presse",
		},
		dir: {
			es: "prensa",
			de: "presse-pr",
		},
		href: "press",
	},
	[Dirs.pages]: {
		breadcrumb: {
			es: "Pagina",
			de: "Seiten",
		},
		dir: {
			es: "pagina",
			de: "seiten",
		},
		href: "pages",
	},
	[Dirs.property]: {
		dir: {
			es: "listing",
			de: "listing",
		},
		href: "listing",
	},
	[Dirs.imprint]: {
		breadcrumb: {
			es: "Aviso Legal",
			de: "Impressum",
		},
		dir: {
			es: "aviso-legal",
			de: "impressum",
		},
		href: "imprint",
	},
	[Dirs.applyAsAgent]: {
		breadcrumb: {
			es: "Ser Agente Inmobiliario",
			de: "Makler werden",
		},
		dir: {
			es: "ser-agente-inmobiliario",
			de: "immobilienmakler-werden",
		},
		href: "apply-as-agent",
	},
	[Dirs.about]: {
		breadcrumb: {
			es: "Quienes Somos",
			de: "Über uns",
		},
		dir: {
			es: "quienes-somos",
			de: "ueber-uns",
		},
		href: "about",
	},
	[Dirs.buy]: {
		breadcrumb: {
			es: "Comprar",
			de: "Kaufen",
		},
		dir: {
			es: "comprar",
			de: "kaufen",
		},
		href: "buy",
	},
	[Dirs.search]: {
		breadcrumb: {
			es: "Buscar",
			de: "Suchen",
		},
		dir: {
			es: "search",
			de: "search",
		},
		href: "search",
	},
	[Dirs.sell]: {
		breadcrumb: {
			es: "Vender",
			de: "Verkaufen",
		},
		dir: {
			es: "vender",
			de: "verkaufen",
		},
		href: "sell",
	},
	[Dirs.faq]: {
		breadcrumb: {
			es: "Preguntas frecuentes",
			de: "Fragen und Antworten",
		},
		dir: {
			es: "preguntas-frecuentes",
			de: "faq",
		},
		href: "faq",
	},
	[Dirs.privacyPolicy]: {
		dir: {
			es: "politica-de-privacidad",
			de: "datenschutz",
		},
		href: "privacy",
	},
	[Dirs.living]: {
		breadcrumb: {
			es: "Vivendo En",
			de: "Leben in",
		},
		dir: {
			es: "vivir-en",
			de: "leben-in",
		},
		href: "living-in",
	},
	[Dirs.realEstateValuation]: {
		breadcrumb: {
			es: "Valoracion Inmobiliaria",
			de: "Immobilienbewertung",
		},
		dir: {
			es: "valoracion-inmobiliaria",
			de: "immobilienbewertung",
		},
		href: "real-estate-valuation",
	},
	[Dirs.valuation]: {
		breadcrumb: {
			es: "Valoracion",
			de: "Bewertung",
		},
		dir: {
			es: "valoracion",
			de: "bewertung",
		},
		href: "valuation",
	},
	[Dirs.valuationResult]: {
		breadcrumb: {
			es: "Resultado de la valoracion",
			de: "Bewertungsergebnis",
		},
		dir: {
			es: "resultado-de-la-valoracion",
			de: "bewertungsergebnis",
		},
		href: "valuation-result",
	},
	[Dirs.blog]: {
		breadcrumb: {
			es: "Blog",
			de: "Blog",
		},
		dir: {
			es: "blog",
			de: "blog",
		},
		href: "blog",
	},
	[Dirs.region]: {
		dir: {
			es: "region",
			de: "region",
		},
		href: "region",
	},
	[Dirs.rent]: {
		breadcrumb: {
			de: "Vermietung",
			es: "Alquilar",
		},
		dir: {
			de: "vermieten",
			es: "alquilar",
		},
		href: "rent",
	},
	[Dirs.documentTemplates]: {
		breadcrumb: {
			de: "Musterdokumente",
			es: "Plantillas de documentos",
		},
		dir: {
			de: "musterdokumente",
			es: "plantillas-documentos",
		},
		href: "document-templates",
	},
	[Dirs.cookiePolicy]: {
		breadcrumb: {
			de: "Cookie Richtlinie",
			es: "Politica de cookies",
		},
		dir: {
			de: "cookie-richtlinie",
			es: "politica-cookies",
		},
		href: "cookie-policy",
	},
	[Dirs.careers]: {
		breadcrumb: {
			de: "Karriere",
			es: "Carrera",
		},
		dir: {
			de: "karriere",
			es: "carrera",
		},
		href: "careers",
	},
	[Dirs.whistleblower]: {
		breadcrumb: {
			de: "Hinweisgeber",
			es: "Informante",
		},
		dir: {
			de: "hinweisgeber",
			es: "informante",
		},
		href: "whistleblower",
	},
	[Dirs.notFound]: {
		breadcrumb: {
			de: "???",
			es: "???",
		},
		dir: {
			de: "404",
			es: "404",
		},
		href: "not-found",
	},
};

export default dirs;
