import React from "react";

import { FormHelperText } from "@material-ui/core";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";

import { Checkbox } from "@/components/checkbox";
import { StyledCircularProgress } from "@/components/forms/";
import { Transdown } from "@/components/i18n";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import {
	GridAreas,
	ButtonGridArea,
	ConsentGridArea,
	StyledForm,
	EmailGridArea,
} from "@/components/newsletter-signup/components";
import { Typography } from "@/components/typography/typography";
import { Button, ButtonSize, StyledArrowRightIcon } from "@/design-system/atoms/button";
import { StyledCheckboxLabel, StyledTextField } from "@/design-system/molecules/form/checkbox";
import { useNewsletterSignup } from "@/hooks/newsletter";
import type { TextCollection } from "@/types/contentful-api";
import { VALIDATION_PATTERNS } from "@/utils/validation";

export enum FormKeys {
	email = "newsletterEmail",
	consent = "newsletterConsent",
}

export const NewsletterSignup: React.FC<{ footer?: boolean; textCollection: TextCollection }> = ({
	footer,
	textCollection,
}) => {
	const { t } = useTranslation(["forms", "valuation"]);
	const postErrorMessage = textCollection.items.find(({ id }) => id === "postError").description;

	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onChange",
	});

	// Take care of duplicate keys in cases where the component is on the page twice
	const emailKey = footer ? `${FormKeys.email}Footer` : FormKeys.email;
	const checkboxKey = footer ? `${FormKeys.consent}Footer` : FormKeys.consent;

	const { error, loading, subscribe, success } = useNewsletterSignup();

	const handleFormSubmit = React.useCallback(
		({ [emailKey]: newsletterEmail }) => {
			void subscribe(newsletterEmail);
		},
		[emailKey, subscribe]
	);

	const WrappingComponent = footer ? "div" : GridAreas;

	return success ? (
		<Typography data-test-id={footer && `footer-newsletter:success`}>
			{t("forms:newsletter.success")}
		</Typography>
	) : error ? (
		<div>
			<Md source={postErrorMessage} />
		</div>
	) : (
		<StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
			<WrappingComponent>
				<EmailGridArea>
					<StyledTextField
						fullWidth
						light={footer}
						data-test-id={footer && "footer-newsletter:email"}
						error={Boolean(errors[emailKey])}
						helperText={!errors[emailKey] && " "}
						id={emailKey}
						inputRef={register({
							required: true,
							pattern: VALIDATION_PATTERNS.email,
						})}
						label={t("formal.email.label")}
						name={emailKey}
					/>
					{errors[emailKey] && (
						<>
							<FormHelperText
								error
								data-test-id={footer && `footer-newsletter:error-email`}
								role="alert"
							>
								{t("formal.email.error")}
							</FormHelperText>
							<Spacer spacing="xxxs" />
						</>
					)}
				</EmailGridArea>
				<ConsentGridArea>
					<StyledCheckboxLabel
						light={footer}
						data-test-id={footer && `footer-newsletter:consent`}
						label={<Transdown i18nKey="forms:newsletter.consent.label" />}
						inputRef={register({ required: true })}
						error={Boolean(errors[checkboxKey])}
						control={<Checkbox name={checkboxKey} />}
					/>
					{errors[checkboxKey] && (
						<FormHelperText
							error
							data-test-id={footer && `footer-newsletter:error-consent`}
							role="alert"
						>
							{t("forms:consent.error")}
						</FormHelperText>
					)}
				</ConsentGridArea>
				<ButtonGridArea footer={footer}>
					<Button
						color={footer ? "secondary" : undefined}
						type="submit"
						data-test-id={footer && `footer-newsletter:submit`}
						disabled={loading}
						size={ButtonSize.small}
						style={{ flex: 1 }}
					>
						{loading && <StyledCircularProgress size={24} color="inherit" />}
						{t("forms:send")}
						<StyledArrowRightIcon />
					</Button>
				</ButtonGridArea>
			</WrappingComponent>
		</StyledForm>
	);
};
