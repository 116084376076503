import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FONT_WEIGHT } from "@/theme";
import type { PropsWithTheme } from "@/theme";

export const StyledTableWrapper = styled.div`
	width: 100%;
	overflow-x: auto;
`;

export const StyledTable = styled.table`
	min-width: 100%;
	border-collapse: collapse;
	font-size: 1rem;
`;

export const StyledTr = styled.tr<PropsWithTheme<HTMLTableRowElement>>`
	${({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.freeze[300]};
	`};
`;

export const StyledTd = styled.td`
	padding: var(--spacing-xs) var(--spacing-xxs);
	font-weight: ${FONT_WEIGHT.light};
	vertical-align: top;

	p:first-child,
	ul:first-child {
		margin-top: 0;
	}
`;

export const StyledTh = styled.th`
	padding: var(--spacing-xs) var(--spacing-xxs);
	font-weight: bold;
	text-align: left;
`;
