const fractionDigits = {
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
};

export const formatPrice = (value: number | string, currency: string, locale: string) =>
	new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
		...fractionDigits,
	}).format(typeof value === "string" ? Number.parseInt(value, 10) : value);

export const formatNumber = (value: number | string, locale: string) =>
	new Intl.NumberFormat(locale, {
		...fractionDigits,
	}).format(typeof value === "string" ? Number.parseInt(value, 10) : value);

// Converts a given value to a float from 0 to 1
export const toOpacityValue = (value: number, max: number): number => {
	return value === max || max === 0 ? 1 : value === 0 ? 0 : Math.min(value, max) / max;
};
