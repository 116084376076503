export interface ValidationPatterns {
	tel: RegExp;
	email: RegExp;
	url: RegExp;
	zipCode: RegExp;
	slug: RegExp;
	leadingOrTrailingSpaces: RegExp;
}

export const VALIDATION_PATTERNS: ValidationPatterns = {
	tel: /^\+?\d{1,2}[-\s]?\d{2,4}[-\s]?\d{2,8}$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
	// eslint-disable-next-line  no-useless-escape
	url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=+$,\w]+@)?[A-Za-z\d.\-]+|(?:www\.|[\-;:&=+$,\w]+@)[A-Za-z\d.\-]+)((?:\/[+~%/.\w\-_]*)?\??[\-+=&;%@.\w_]*#?[.!/\\\w]*)?)/,
	zipCode: /^\d{5}$/,
	leadingOrTrailingSpaces: /^\s+|\s+$/,
	slug: /^[a-z\d-]+$/,
};
