import type { VFC } from "react";
import React from "react";

import { NoSsr } from "@material-ui/core";
import MuiCheckbox from "@material-ui/core/Checkbox";

import type { CheckboxProps } from "@/components/checkbox/types";

// This is using NoSsr as there is some issue with className inconsistencies between SSR and client
// with MUI checkboxes specifically for some reason.
// https://stackoverflow.com/questions/65580751/why-do-material-ui-checkbox-appear-twice-on-the-first-render
// https://stackoverflow.com/questions/62331868/wrong-class-names-on-pages-with-different-content-rendered-on-client-than-on-ser
// https://stackoverflow.com/questions/50685175/react-material-ui-warning-prop-classname-did-not-match
export const Checkbox: VFC<CheckboxProps> = ({ checked, name, handleChange, ...props }) => {
	return (
		<NoSsr>
			<MuiCheckbox {...props} checked={checked} name={name} onChange={handleChange} />
		</NoSsr>
	);
};
