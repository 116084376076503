import React, { createContext, useContext } from "react";

// Type definition for TypeScript users
interface FeatureFlagProviderProps {
	value: string[];
	children: React.ReactNode;
}

// Define the context with an initial empty array
const FeatureFlagContext = createContext<string[]>([]);

// Adjust the provider to accept flags via a value prop
export const FeatureFlagProvider = ({ value, children }: FeatureFlagProviderProps) => {
	return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};

// Export a hook to access the context
const useFeatureFlags = () => useContext(FeatureFlagContext);

// Check if a flag is enabled (included in the flags string[])
export const useFeatureFlag = (flagName: string) => {
	const flags = useFeatureFlags();
	return flags.includes(flagName);
};
