import React from "react";

import Head from "next/head";

import { getOGImageContents } from "@/utils/seo";

import type { SEOTagsProps } from "./types";

/**
 * SEO relevant tags with dynamic content.
 *
 * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/The_head_metadata_in_HTML
 * @see https://moz.com/blog/meta-data-templates-123
 */
export const SEOTags: React.FC<SEOTagsProps> = ({ meta, url }) => {
	const {
		metaDescription,
		metaTitle,
		metaKeywords,
		alternateHrefLang,
		alternateHref,
		openGraphDescription,
		openGraphImage,
		openGraphTitle,
		robots,
		schemaDescription,
		schemaImage,
		schemaTitle,
		twitterAuthor,
		twitterDescription,
		twitterImage,
		twitterPublisher,
		twitterTitle,
	} = meta;

	const { largeWidth, largeHeight, smallUrl, smallSize } = getOGImageContents(
		openGraphImage?.url
	);

	return (
		<Head>
			<title>{metaTitle} - Evernest</title>
			{/* Basic meta tags https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/The_head_metadata_in_HTML */}
			<meta key="meta-description" name="description" content={metaDescription} />
			<meta name="keywords" content={metaKeywords} />

			{/* Schema.org markup */}
			<meta itemProp="description" content={schemaDescription ?? metaDescription} />
			<meta itemProp="name" content={schemaTitle ?? metaTitle} />
			{schemaImage && <meta itemProp="image" content={schemaImage?.url} />}

			{/* Twitter Card https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image */}
			<meta name="twitter:title" content={`${twitterTitle ?? metaTitle} - Evernest`} />
			<meta name="twitter:description" content={twitterDescription ?? metaDescription} />
			{twitterImage && <meta name="twitter:image:src" content={twitterImage?.url} />}
			{twitterPublisher && <meta name="twitter:site" content={twitterPublisher} />}
			{twitterAuthor && <meta name="twitter:creator" content={twitterAuthor} />}

			{/* Open Graph  https://ogp.me/ */}
			<meta property="og:type" content="website" />
			<meta property="og:title" content={`${openGraphTitle ?? metaTitle} - Evernest`} />
			<meta
				key="og-description"
				property="og:description"
				content={openGraphDescription ?? metaDescription}
			/>
			{openGraphImage?.url && (
				<>
					<meta property="og:image" content={openGraphImage?.url} />
					<meta property="og:image:type" content="image/jpeg" />
					<meta property="og:image:width" content={largeWidth} />
					<meta property="og:image:height" content={largeHeight} />
					<meta property="og:image" content={smallUrl} />
					<meta property="og:image:type" content="image/jpeg" />
					<meta property="og:image:width" content={smallSize} />
					<meta property="og:image:height" content={smallSize} />
				</>
			)}
			<meta property="og:url" content={url} />
			<meta property="og:site_name" content="Evernest" />

			{/* HrefLang https://moz.com/learn/seo/hreflang-tag */}
			{alternateHrefLang && alternateHref && (
				<link rel="alternate" hrefLang={alternateHrefLang} href={alternateHref} />
			)}

			{/* Canonical  https://moz.com/blog/rel-canonical */}
			{url && <link rel="canonical" href={url} />}

			{/* Robots https://moz.com/learn/seo/robots-meta-directives */}
			{robots && <meta name="robots" content={robots.join(",")} />}
		</Head>
	);
};
