import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import type { PropsWithTheme } from "@/theme";

// Additional specificity required to overwrite underline border-color on dark background
export const StyledTextField = styled(TextField, {
	shouldForwardProp: (prop: string) => prop !== "light",
})<PropsWithTheme<HTMLLabelElement> & { light?: boolean }>`
	${({ theme: { palette }, light }) => css`
		.MuiInputBase-root,
		.MuiFormLabel-root,
		& label.Mui-focused {
			color: ${light ? palette.freeze[0] : palette.freeze[1000]};
		}
		.MuiInput-underline::after,
		.MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
			border-color: ${light ? palette.freeze[0] : palette.freeze[1000]};
		}
	`};
`;

export const StyledCheckboxLabel = styled(FormControlLabel, {
	shouldForwardProp: (prop: string) => prop !== "error" && prop !== "light",
})<PropsWithTheme<HTMLLabelElement> & { error?: boolean; light?: boolean }>`
	align-items: flex-start;
	${({ theme: { palette }, error, light }) => css`
		.MuiFormControlLabel-label {
			color: ${light ? palette.freeze[0] : palette.freeze[1000]};
			font-size: 12px;
		}
		.MuiSvgIcon-root {
			fill: ${error ? palette.red[500] : light ? palette.freeze[0] : palette.freeze[1000]};
		}
	`};

	&.MuiFormControlLabel-root {
		display: flex;
		align-items: flex-start;
		margin-right: 0;
	}

	.MuiButtonBase-root {
		margin: 0 6px;
		padding: 0;
	}

	.MuiFormControlLabel-label {
		padding-top: 3px;
	}
`;
