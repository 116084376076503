import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

import type { StyledHeaderProps } from "./types";

export const StyledHeader = styled.header<PropsWithTheme>`
	height: var(--header-height-ref);
`;

export const StyledHeaderContainer = styled.div<StyledHeaderProps>`
	height: var(--header-height, inherit);
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	${({ theme: { palette }, fixed, plain, sticky }) => css`
		position: ${fixed ? "fixed" : sticky ? "sticky" : "absolute"};
		background: ${plain ? "inherit" : "none"};
		color: ${plain ? "currentColor" : palette.freeze[0]};
	`};
`;

export const StyledHeaderBackground = styled.div<StyledHeaderProps>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	${({ theme: { palette }, plain }) => css`
		background: ${plain ? "inherit" : palette.freeze[1000]};
	`};
`;
export const StyledHeaderContent = styled.div<PropsWithTheme>`
	height: inherit;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
	position: relative;
`;
