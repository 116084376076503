import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

import { Panel } from "./panel";
import type { StyledBackdropProps, StyledPanelProps, StyledToggleProps } from "./types";
import { Anchor } from "./types";

export const StyledSidebar = styled(Panel)`
	display: flex;
	flex-direction: column;

	> nav {
		flex: 1;
	}
`;

export const Separator = styled.span<PropsWithTheme>`
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 1px;
	margin: 0 var(--spacing-xs);
	color: currentColor;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[300]};
	`};
`;

export const HeaderContent = styled.div`
	display: flex;
	padding: 0 var(--spacing-xs);
`;

export const StyledBackdrop = styled.div<StyledBackdropProps>`
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition-duration: 0.3s;
	transition-property: opacity, visibility;
	${({ theme: { palette } }) =>
		css`
			background: ${palette.freeze[1000]};
		`};
	${({ open }) =>
		open &&
		css`
			visibility: visible;
			pointer-events: all;
			opacity: 0.7;
		`};
`;

export const StyledPanel = styled.aside<StyledPanelProps>`
	position: fixed;
	z-index: 100;
	transition-duration: 0.3s;
	transition-property: transform;
	overflow: auto;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[0]};
	`};
	${({ anchor, width, open }) => {
		switch (anchor) {
			case Anchor.bottom: {
				return css`
					bottom: 0;
					left: 0;
					right: 0;
					transform: translate3d(0, ${open ? 0 : "100%"}, 0);
				`;
			}
			case Anchor.right: {
				return css`
					bottom: 0;
					top: 0;
					right: 0;
					width: ${width};
					max-width: calc(100vw - 80px);
					transform: translate3d(${open ? 0 : "100%"}, 0, 0);
				`;
			}
			case Anchor.top: {
				return css`
					top: 0;
					left: 0;
					right: 0;
					transform: translate3d(0, ${open ? 0 : "-100%"}, 0);
				`;
			}
			case Anchor.left:
			default: {
				return css`
					bottom: 0;
					left: 0;
					top: 0;
					width: ${width};
					max-width: calc(100vw - 80px);
					transform: translate3d(${open ? 0 : "-100%"}, 0, 0);
				`;
			}
		}
	}};
`;

export const blockScroll = css`
	body {
		overflow: hidden;
		height: 100vh;
	}
`;

export const StyledBurgerWrapper = styled.div`
	height: 48px;
	width: 48px;
	position: relative;
`;

export const StyledBurgerBun = styled.span`
	height: 2px;
	width: 16px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -1px -8px;
	background: currentColor;
	transition-duration: 0.3s;
	transition-property: transform;
`;

export const bunTransformation = {
	top: {
		open: "translate3d(0, 0, 0) rotate3d(0,0,1,45deg)",
		closed: "translate3d(0, -4px, 0) rotate3d(0,0,0,0deg)",
	},
	bottom: {
		open: "translate3d(0, 0, 0) rotate3d(0,0,1,-45deg)",
		closed: "translate3d(0, 4px, 0) rotate3d(0,0,0,0deg)",
	},
};

export const StyledToggle = styled.button<StyledToggleProps>`
	position: fixed;
	top: 12px;
	right: 16px;
	z-index: 101;
	height: 48px;
	width: 48px;
	background: none;
	font-size: 1em;
	border: 0;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	box-shadow: none;
	display: flex;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export const HiddenText = styled.span`
	position: absolute;
	top: -100vh;
	left: -100vw;
	display: block;
	height: 0;
	width: 0;
	opacity: 0;
`;
