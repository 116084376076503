import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const StyledForm = styled.form`
	width: 100%;
`;

export const GridAreas = styled.div<PropsWithTheme>`
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			display: grid;
			grid-template-areas:
				"email button"
				"consent consent";
			grid-template-columns: 1fr 220px;
			grid-column-gap: var(--spacing-m);
		}
	`};
`;

export const EmailGridArea = styled.div`
	grid-area: email;
`;

export const ConsentGridArea = styled.div`
	grid-area: consent;
`;

export const ButtonGridArea = styled.div<PropsWithTheme & { footer?: boolean }>`
	display: flex;
	grid-area: button;
	align-self: flex-start;
	${({ theme: { mq }, footer }) => css`
		padding-top: var(--spacing-xxs);
		@media ${mq.m} {
			position: relative;
			top: 7px;
			padding-top: ${footer ? "var(--spacing-xxs)" : 0};
		}
	`};
`;
