import React from "react";

import { StyledProgressBar } from "./styles";
import type { ProgressBarProps } from "./types";

export const ProgressBar: React.FC<ProgressBarProps> = props => {
	const { label, current, segments, ...rest } = props;
	const percentage = Math.ceil((current / segments) * 100);
	return (
		<StyledProgressBar
			aria-valuemin={0}
			aria-valuemax={segments}
			aria-valuenow={current}
			aria-valuetext={label}
			percentage={percentage}
			role="progressbar"
			{...rest}
		/>
	);
};
