import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Typography } from "@/components/typography/typography";
import { ButtonRaw } from "@/design-system/atoms/button";
import { Icon } from "@/design-system/atoms/icons";
import type { PropsWithTheme } from "@/theme";

const StyledRegionSelector = styled.div`
	position: relative;
	align-self: center;

	&::after {
		/* Trick to not lose hover between two separate elements */
		position: absolute;
		height: inherit;
		top: 0;
		right: 0;
		bottom: -4px;
		left: -35px;
		cursor: pointer;
	}

	&:hover::after {
		content: "";
	}
`;

const RegionMenuButton = styled(ButtonRaw)<PropsWithTheme & { open: boolean }>`
	display: flex;
	align-items: center;
	align-self: center;
	justify-content: center;
	width: 72px;
	height: 40px;
	gap: 4px;
	border-radius: var(--spacing-xxxs);
	cursor: pointer;
	${({ theme: { palette }, open }) => css`
		color: ${palette.freeze[0]};
		background: ${open ? palette.freeze[1000] : "none"};

		&:hover {
			background: ${palette.freeze[1000]};
		}
	`};
`;

const RegionMenu = styled.div<PropsWithTheme & { open: boolean }>`
	position: absolute;
	top: 42px;
	right: 0;
	gap: var(--spacing-xxxs);
	flex-direction: column;
	width: 230px;
	padding: var(--spacing-xs) 0;
	border-radius: var(--spacing-xxxs);
	box-shadow: 0 30px 27px 0 rgba(0, 0, 0, 0.09);
	${({ theme: { palette }, open }) => css`
		display: ${open ? "flex" : "none"};
		background: ${palette.freeze[0]};
	`};
`;

const RegionLink = styled.a<PropsWithTheme>`
	position: relative;
	padding: 0 var(--spacing-s);
	text-align: left;
	text-decoration: none;

	&:hover::before {
		content: "";
		position: absolute;
		top: -1px;
		bottom: 0;
		left: 4px;
		width: 3px;
		border-radius: 1px;
		background: black;
		pointer-events: none;
	}
`;

const RegionLinkText = styled(Typography)<PropsWithTheme & { active: boolean }>`
	font-size: 14px;
	${({ theme: { palette }, active }) => css`
		color: ${palette.freeze[1000]};
		font-weight: ${active ? "600" : "300"};
	`};
`;

export const RegionSelectorHeader: React.FC = () => {
	const { locale } = useRouter();
	const { t } = useTranslation(["common"]);

	const [open, setOpen] = React.useState(false);
	const menuButton = React.useRef<HTMLButtonElement>();

	const closeMenu = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Escape") {
			event.preventDefault();
			setOpen(false);
			menuButton?.current.focus();
		}
	};

	return (
		<StyledRegionSelector
			onKeyDown={closeMenu}
			onMouseEnter={() => {
				setOpen(true);
			}}
			onMouseLeave={() => {
				setOpen(false);
			}}
		>
			<RegionMenuButton
				ref={menuButton}
				open={open}
				aria-expanded={open}
				aria-controls="region-selector"
				aria-label={t("navigation:region-selector:label")}
				data-test-id="region-selector"
				onClick={() => {
					setOpen(state => !state);
				}}
			>
				<Icon aria-hidden="true" icon="world" />
				<Icon aria-hidden="true" icon={open ? "chevronUp" : "chevronDown"} />
			</RegionMenuButton>
			<RegionMenu id="region-selector" open={open}>
				<Link passHref href="/" as="/" locale="de">
					<RegionLink data-test-id="region-germany">
						<RegionLinkText tight active={locale === "de"}>
							{t("navigation:region-selector:germany")}
						</RegionLinkText>
					</RegionLink>
				</Link>
				<Link passHref href="/" as="/" locale="es">
					<RegionLink data-test-id="region-spain">
						<RegionLinkText tight active={locale === "es"}>
							{t("navigation:region-selector:spain")}
						</RegionLinkText>
					</RegionLink>
				</Link>
			</RegionMenu>
		</StyledRegionSelector>
	);
};
