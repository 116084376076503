import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import type { LinkProps } from "next/link";
import Link from "next/link";
import { useRouter } from "next/router";

import { Column, Flex, Grid, Row } from "@/components/grid";
import type { ExpandableNavProps } from "@/components/layout/types";
import { NavigationType } from "@/components/layout/types";
import { ButtonRaw } from "@/design-system/atoms/button";
import { EvernestLogo } from "@/design-system/atoms/evernest-logo";
import { Icon as EvernestIcon, IconSize } from "@/design-system/atoms/icons";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { FONT_WEIGHT } from "@/theme";
import type { NavigationItem } from "@/types/contentful-api";
import { getURL } from "@/utils/urls";

import { HeaderLink } from "./header-link";

const StyledExpandableNav = styled.div`
	position: relative;
	font-size: 20px;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			font-size: 14px;
		}
	`};
`;

const StyledExpandableNavHeader = styled.div`
	display: flex;
	justify-content: space-between;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			justify-content: normal;
		}
	`};
`;

export const NavLink = styled.a<{ as?: keyof JSX.IntrinsicElements }>`
	display: block;
	padding: var(--spacing-xxs) var(--spacing-xs);
	color: currentColor;
	font-size: 20px;
	font-weight: ${FONT_WEIGHT.light};
	line-height: 28px;
	text-decoration: none;
	white-space: nowrap;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			font-size: 14px;
			padding: var(--spacing-xs) var(--spacing-xxs);
		}
	`}
`;

export const StyledExpandableDropdown = styled.div<{
	expanded?: boolean;
}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	color: black;
	${({ theme: { mq, palette }, expanded }) => css`
		visibility: ${expanded ? "visible" : "hidden"};
		height: ${expanded ? "auto" : "0"};
		@media ${mq.l} {
			position: absolute;
			box-shadow: 0 30px 27px 0 rgba(0, 0, 0, 0.09);
			margin: calc(var(--spacing-xxs) * -1) 0 0;
			padding: var(--spacing-xs) 0;
			border-radius: 10px;
			min-width: max-content;
			background-color: ${palette.freeze[0]};
			top: 100%;
			left: 0;
		}
	`};
`;

export const StyledHeaderDropdownButton = styled(ButtonRaw)`
	margin-right: var(--spacing-xxs);
	padding: 0;
	${({ theme: { mq, palette } }) => css`
		@media ${mq.l} {
			color: ${palette.freeze[0]};
			margin-left: calc(var(--spacing-xxs) * -1);
			margin-right: 0;
			position: relative;
			top: 1px;
		}
	`};
`;

export const CallLink = styled.a`
	display: flex;
	position: absolute;
	top: 50%;
	right: 73px;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	margin-top: -24px;
`;

export const EvernestHeaderContent: React.FC = ({ children }) => {
	return (
		<Grid overflow>
			<Row>
				<Column raw>
					<Flex>
						<EvernestLogo />
						{children}
					</Flex>
				</Column>
			</Row>
		</Grid>
	);
};

export const ActiveHeaderLink: React.FC<LinkProps> = ({ children, href, ...props }) => {
	const { locale } = useRouter();
	const trackEvent = useTrackingEvent({
		"Event Category": "Navigation",
		"Event Action": "Click",
		"Event Label": "Navigation Used",
	});

	return (
		<Link {...props} passHref href={href} locale={locale} prefetch={false}>
			<HeaderLink onClick={trackEvent}>{children}</HeaderLink>
		</Link>
	);
};

export const ExpandableNav: React.VFC<ExpandableNavProps> = ({
	menuItem,
	type = NavigationType.header,
}) => {
	const {
		sys: { id },
		label,
	} = menuItem;
	const { t } = useTranslation("navigation");
	const { locale } = useRouter();

	// Used to programmatically focus the toggle button in 'handleKeyDown' function
	const buttonRef = React.useRef<HTMLButtonElement>(null);

	const [expanded, setExpanded] = React.useState(false);
	const expand = () => {
		setExpanded(true);
	};

	const collapse = () => {
		setExpanded(false);
	};

	const toggle = () => {
		setExpanded(previousState => !previousState);
	};

	// If user presses Escape key while inside sub-menu, close the sub-menu and focus toggle button
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Escape") {
			collapse();
			buttonRef.current.focus();
		}
	};

	const linkConfig = getURL(menuItem, locale);
	const hasSubMenuItems = menuItem.itemsCollection.items.length > 0;
	const ExpandableNavHeader = hasSubMenuItems ? StyledExpandableNavHeader : React.Fragment;

	return (
		<StyledExpandableNav
			onMouseEnter={type === NavigationType.header ? expand : null}
			onMouseLeave={type === NavigationType.header ? collapse : null}
		>
			<ExpandableNavHeader>
				{linkConfig.href ? (
					<Link
						passHref
						href={linkConfig.href}
						as={linkConfig.as}
						locale={locale}
						prefetch={false}
					>
						<NavLink>{menuItem.label}</NavLink>
					</Link>
				) : (
					<NavLink as="span">{menuItem.label}</NavLink>
				)}
				{hasSubMenuItems && (
					<StyledHeaderDropdownButton
						ref={buttonRef}
						aria-controls={id}
						aria-label={
							expanded
								? t("navigation:close-submenu", { label })
								: t("navigation:open-submenu", { label })
						}
						onClick={toggle}
					>
						<EvernestIcon
							icon={expanded ? "chevronUp" : "chevronDown"}
							size={type === NavigationType.sidebar ? IconSize.l : IconSize.m}
						/>
					</StyledHeaderDropdownButton>
				)}
			</ExpandableNavHeader>
			{hasSubMenuItems && (
				<StyledExpandableDropdown
					id={id}
					aria-label={t("navigation:submenu", { label })}
					aria-haspopup="true"
					aria-expanded={expanded}
					expanded={expanded}
					onKeyDown={handleKeyDown}
				>
					{menuItem.itemsCollection.items
						// We expect there to always be a link in sub-menu items so filter out invalid
						.filter(
							subMenuItem =>
								subMenuItem?.internalEntryLink || subMenuItem?.externalUrl
						)
						.map((subMenuItem: NavigationItem) => {
							const sublinkConfig = getURL(subMenuItem, locale);
							return (
								<ActiveHeaderLink
									key={subMenuItem.sys.id}
									href={sublinkConfig.href}
									as={sublinkConfig.as}
								>
									{subMenuItem.label}
								</ActiveHeaderLink>
							);
						})}
				</StyledExpandableDropdown>
			)}
		</StyledExpandableNav>
	);
};
