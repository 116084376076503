import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { ButtonLink, ButtonSize } from "@/design-system/atoms/button";
import type { NavigationItem } from "@/types/contentful-api";
import type { LinkConfig } from "@/utils/urls";
import { getURL } from "@/utils/urls";

interface ActionButtonLinkProps {
	navigationItem: NavigationItem;
	dark?: boolean;
}

export const ActionButtonLink: React.VFC<ActionButtonLinkProps> = ({ navigationItem, dark }) => {
	const { locale } = useRouter();

	const [url, setUrl] = React.useState<LinkConfig>(
		navigationItem ? getURL(navigationItem, locale) : { href: null, as: null }
	);

	const isExternalLink = !navigationItem?.internalEntryLink && navigationItem?.externalUrl;

	React.useEffect(() => {
		// If an internal link, append URL params to URL
		if (navigationItem?.internalEntryLink) {
			setUrl(value => ({
				href: `${value.href}${window.location.search}`,
				as: `${value.as}${window.location.search}`,
			}));
		}
	}, []);

	return url?.href?.length > 0 ? (
		<Link passHref href={url.href} as={url.as}>
			<ButtonLink
				color={dark ? "secondary" : undefined}
				size={ButtonSize.small}
				target={isExternalLink ? "_blank" : undefined}
			>
				{navigationItem?.label}
			</ButtonLink>
		</Link>
	) : null;
};
