import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated, useSpring } from "@react-spring/web";
import { useMeasure } from "react-use";

import { Icon } from "@/design-system/atoms/icons";
import type { PropsWithTheme } from "@/theme";

import type { AccordionElement, AccordionProps, StyledAccordionProps, StyledPanelProps } from "./types";

/* @todo: revisit border style with token refactor */
export const StyledAccordion = styled.div<
	StyledAccordionProps & { dark?: boolean; border?: boolean }
>`
	${({ theme: { palette }, dark, border }) =>
		border &&
		css`
			border-bottom: 1px solid ${dark ? palette.freeze[0] : palette.freeze[500]};
		`};
`;

export const StyledButton = styled.button<{ tight?: boolean }>`
	width: 100%;
	border: none;
	border-radius: 0;
	appearance: none;
	background: none;
	color: currentColor;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	${({ tight }) => css`
		padding: 0 0 ${tight ? "" : "var(--spacing-xs)"};
	`};
`;

export const StyledInnerButtonWrapper = styled.div`
	display: flex;
	align-content: flex-start;
	align-items: flex-start;
	height: 100%;
	overflow: hidden;
	text-align: left;
`;

export const StyledPanel = styled.div<StyledPanelProps>`
	padding-bottom: var(--spacing-xs);
	padding-left: var(--spacing-s);
	> :first-of-type {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			padding-left: var(--spacing-m);
		}
	`};
`;

export const StyledAnimatedPanelWrapper = styled(animated.div)`
	overflow: hidden;
`;

export const StyledIconWrapper = styled(animated.div, {
	shouldForwardProp: (prop: string) => prop !== "dark",
})<PropsWithTheme & { dark?: boolean }>`
	width: var(--spacing-s);
	height: var(--spacing-xs);
	${({ theme: { mq, palette }, dark }) => css`
		color: ${dark ? palette.freeze[0] : palette.freeze[500]};
		@media ${mq.l} {
			width: var(--spacing-m);
			height: var(--spacing-s);
		}
	`};
`;

export const StyledAnimatedIconWrapper = styled(animated.span)`
	display: inline-flex;
`;

export const AccordionLabel = styled.span<PropsWithTheme>`
	display: inline-flex;
	flex: 1;
	align-content: center;
	align-items: center;
	min-height: var(--spacing-xs);
	line-height: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			width: var(--spacing-s);
			min-height: var(--spacing-s);
		}
	`};
`;

export const Accordion = React.forwardRef<AccordionElement, AccordionProps>(
	(
		{
			id,
			children,
			dark,
			border,
			title,
			headerComponent,
			springConfig,
			icon: IconComponent,
			iconExpanded: IconComponentExpanded,
			rotateOnExpand,
			iconPosition,
			tight,
			...props
		},
		ref
	) => {
		const [expanded, setExpanded] = React.useState(false);
		const [panelBottomPadding, setPanelBottomPadding] = React.useState(0);

		const handleClick = () => {
			setExpanded(!expanded);
		};

		const panelId = `${id}-panel`;

		const [useMeasureRef, { height }] = useMeasure<HTMLDivElement>();

		const springPanelProps = useSpring({
			config: springConfig,
			height: expanded ? height + panelBottomPadding : 0,
			opacity: expanded ? 1 : 0,
		});

		const { z } = useSpring({
			config: springConfig,
			z: expanded ? rotateOnExpand : 0,
		});

		React.useEffect(() => {
			const panelElement = document.querySelector(`#${panelId}`);
			/* istanbul ignore next */
			if (panelElement) {
				const paddingBottom = getComputedStyle(panelElement).paddingBottom;
				setPanelBottomPadding(Number.parseInt(paddingBottom, 10));
			}
		}, [panelId, setPanelBottomPadding]);

		const HeaderComponent = React.useMemo(() => headerComponent, [headerComponent]);
		const icon = React.useMemo(
			() => (
				<StyledIconWrapper dark={dark}>
					<StyledAnimatedIconWrapper
						style={{
							transform: z.to((value: number) => `rotate3d(0,0,1,${value}turn)`),
						}}
					>
						{IconComponentExpanded ? (
							expanded ? (
								<IconComponentExpanded />
							) : (
								<IconComponent />
							)
						) : (
							<IconComponent />
						)}
					</StyledAnimatedIconWrapper>
				</StyledIconWrapper>
			),
			[dark, z, IconComponentExpanded, IconComponent, expanded]
		);

		return (
			<StyledAccordion {...props} ref={ref} dark={dark} border={border}>
				<HeaderComponent>
					<StyledButton
						tight={tight}
						aria-controls={panelId}
						aria-expanded={expanded}
						id={id}
						onClick={handleClick}
					>
						<StyledInnerButtonWrapper>
							{iconPosition === "left" && icon}
							<AccordionLabel data-test-id="styled-inner-button-wrapper-label">
								{title}
							</AccordionLabel>
							{iconPosition === "right" && icon}
						</StyledInnerButtonWrapper>
					</StyledButton>
				</HeaderComponent>
				<StyledAnimatedPanelWrapper
					style={{
						height: springPanelProps.height,
						visibility: springPanelProps.opacity.to(value =>
							/* istanbul ignore next */
							value === 0 ? "hidden" : "visible"
						),
					}}
				>
					<StyledPanel
						ref={useMeasureRef}
						aria-labelledby={id}
						id={panelId}
						role="region"
					>
						<div>{children}</div>
					</StyledPanel>
				</StyledAnimatedPanelWrapper>
			</StyledAccordion>
		);
	}
);

Accordion.defaultProps = {
	dark: false,
	headerComponent: "div",
	springConfig: undefined,
	icon: () => <Icon aria-hidden="true" icon="plus" />,
	rotateOnExpand: 0.125,
	iconPosition: "left",
	border: true,
};
