import React from "react";

export const FooterIntersectionContext = React.createContext<{
	intersecting?: boolean;
	setIntersecting?: React.Dispatch<React.SetStateAction<boolean>>;
}>({});

export const FooterIntersectionProvider: React.FC = ({ children }) => {
	const [intersecting, setIntersecting] = React.useState(false);
	const context = React.useMemo(
		() => ({ intersecting, setIntersecting }),
		[intersecting, setIntersecting]
	);

	return (
		<FooterIntersectionContext.Provider value={context}>
			{children}
		</FooterIntersectionContext.Provider>
	);
};

export const WidgetIntersectionContext = React.createContext<{
	intersecting?: boolean;
	setIntersecting?: React.Dispatch<React.SetStateAction<boolean>>;
}>({});

export const WidgetIntersectionProvider: React.FC = ({ children }) => {
	const [intersecting, setIntersecting] = React.useState(false);
	const context = React.useMemo(
		() => ({ intersecting, setIntersecting }),
		[intersecting, setIntersecting]
	);

	return (
		<WidgetIntersectionContext.Provider value={context}>
			{children}
		</WidgetIntersectionContext.Provider>
	);
};

export const FormIntersectionContext = React.createContext<{
	intersecting?: boolean;
	setIntersecting?: React.Dispatch<React.SetStateAction<boolean>>;
	intersectingElement?: boolean;
	setIntersectingElement?: React.Dispatch<React.SetStateAction<boolean>>;
}>({});

export const FormIntersectionProvider: React.FC = ({ children }) => {
	const [intersecting, setIntersecting] = React.useState(false);
	const [intersectingElement, setIntersectingElement] = React.useState(false);
	const context = React.useMemo(
		() => ({ intersecting, setIntersecting, intersectingElement, setIntersectingElement }),
		[intersecting, setIntersecting, intersectingElement, setIntersectingElement]
	);

	return (
		<FormIntersectionContext.Provider value={context}>
			{children}
		</FormIntersectionContext.Provider>
	);
};
