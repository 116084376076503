import React from "react";

import { Global } from "@emotion/react";

import { blockScroll, StyledBackdrop, StyledPanel } from "./styles";
import type { PanelElement, StyledPanelProps } from "./types";

export const Panel = React.forwardRef<PanelElement, StyledPanelProps>(
	({ anchor, children, className, open, onClose, width = "295px", ...props }, ref) => {
		const handleClick = React.useCallback(() => {
			open && onClose();
		}, [open, onClose]);
		return (
			<>
				{open && <Global styles={blockScroll} />}
				<StyledBackdrop
					{...props}
					open={open}
					onClick={handleClick}
					data-test-id="styled-backdrop"
				/>
				<StyledPanel
					{...props}
					anchor={anchor}
					width={width}
					open={open}
					className={className}
					ref={ref}
					data-test-id="styled-panel"
				>
					{children}
				</StyledPanel>
			</>
		);
	}
);
