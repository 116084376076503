import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Link from "next/link";
import { useRouter } from "next/router";

import type { PropsWithTheme } from "@/theme";

const SVGLogo = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1096 157.5" {...props}>
			<path
				fill="currentColor"
				d="M105.29 154.9H0V3.25h103.78v16.68h-84.5V68h78.65v16.71H19.28v53.51h86ZM199.46 154.9h-21.67L120.38 3.25H141L189.06 133 237.15 3.25h19.72ZM382.66 154.9H277.37V3.25h103.78v16.68h-84.5V68h78.65v16.71h-78.65v53.51h86ZM433.13 91.86v63h-19.06V3.25h63.47c33.8 0 49.83 19.06 49.83 44 0 24.48-15.16 38.56-34.23 42.46v.22c1.95 3.25 3.25 5.2 5.64 9.53l32.06 55.46h-22.31l-34-59.79c-1.73-2.6-2.16-3.25-5.85-3.25Zm0-16.47H476c21.23 0 31.84-11.48 31.84-27.73 0-16.46-10.83-27.73-32.06-27.73h-42.65ZM578.56 27.73V154.9h-18.85V3.25h24.7l82.33 127.17V3.25h19.06V154.9h-24.91ZM824.91 154.9H719.62V3.25h103.77v16.68H738.9V68h78.64v16.71H738.9v53.51h86ZM844.45 108.11h19.07c2.81 21.66 19.06 32.93 41.16 32.93 20.15 0 37.7-9.32 37.7-26.22 0-15.38-13.87-21.88-34.45-26.65l-16.46-3.68c-27.74-6.5-42.25-18.63-42.25-40.73 0-26 24.7-43.76 54.38-43.76 30.54 0 52.4 16.46 55.46 45.28H940c-2.38-17.55-15.81-28.6-36.39-28.6-19.07 0-35.53 10.4-35.53 25.57 0 13.86 9.75 19.71 28.6 23.83l18.41 4.33c27.95 6.28 46.58 18.2 46.58 42.46 0 30.12-28.16 44.63-57 44.63-32.49 0-57.4-16.5-60.22-49.39ZM1023.64 19.93h-53.08V3.25H1096v16.68h-52.86v135h-19.5Z"
			/>
		</svg>
	);
};

export const StyledLogo = styled(SVGLogo)<PropsWithTheme>`
	width: 111px;
	height: 16px;
	${({ theme: { mq } }) => css`
		@media print, ${mq.l} {
			width: 153px;
			height: 22px;
		}
	`};
`;

const StyledLink = styled.a`
	color: inherit;
	display: inline-flex;
	text-decoration: none;
`;

export const EvernestLogo = props => {
	const { locale } = useRouter();
	return (
		<Link passHref as="/" href="/" locale={locale}>
			<StyledLink data-test-id="layout:logo" aria-label="Evernest Logo">
				<StyledLogo {...props} />
			</StyledLink>
		</Link>
	);
};
