import type { CSSProperties, ReactNode } from "react";
import React from "react";

import ReactMarkdown from "react-markdown";
import RehypeRaw from "rehype-raw";
import RemarkGfm from "remark-gfm";

import NextLink from "next/link";

import { ActionButtonLink } from "@/components/action-button-link";
import { Column, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import type { MdProps } from "@/components/markdown/types";
import {
	StyledTable,
	StyledTableWrapper,
	StyledTd,
	StyledTh,
	StyledTr,
} from "@/components/table/styled";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type { Markdown as MarkdownProps } from "@/types/contentful-api";
import { AssetFormat } from "@/types/contentful-images";

import {
	StyledImage,
	Link,
	StyledBlockquote,
	StyledIcon,
	StyledButtonLinkContainer,
	StyledMarkdownTypography,
} from "./styled";

// @todo: review MD vs BlogMD implementation and if we should squash both
//  to one component with optional `layout/componentsSet` prop

const reactMDComponents = {
	h1: ({ children }) => (
		<Typography component="h1" variant={TypographyVariant.headlineSerif2XL}>
			{children}
		</Typography>
	),
	h2: ({ children }) => (
		<Typography component="h2" variant={TypographyVariant.headlineSerifLG}>
			{children}
		</Typography>
	),
	h3: ({ children }) => (
		<Typography component="h3" variant={TypographyVariant.headlineSerifSM}>
			{children}
		</Typography>
	),
	h4: ({ children }) => (
		<Typography component="h4" variant={TypographyVariant.headlineSerifXXS}>
			{children}
		</Typography>
	),
	p: ({ children }) => <StyledMarkdownTypography>{children}</StyledMarkdownTypography>,
	href: ({ children, href }) => (
		<NextLink passHref href={href}>
			<Link
				href={href}
				target={!(href.includes("evernest.com/") || href.startsWith("/")) && "_blank"}
				rel="noreferrer"
			>
				{children}
			</Link>
		</NextLink>
	),
	ul: ({ children }) => <Typography component="ul">{children}</Typography>,
	ol: ({ children }) => <Typography component="ol">{children}</Typography>,
	li: ({ children }) => (
		<StyledMarkdownTypography tight component="li">
			{children}
		</StyledMarkdownTypography>
	),
	img: props => {
		// Replace // if start of string with https://
		let imageURL = props.src.replace(/^\/\//, "https://") as string;

		// If it is a Contentful image asset URL
		if (imageURL.includes("images.ctfassets.net")) {
			const url = new URL(imageURL);
			const params = new URLSearchParams(url.search);

			// Add Contentful Image API params
			params.set("fm", AssetFormat.webp);
			params.set("q", "75");
			params.set("w", "900");

			url.search = params.toString();
			imageURL = url.toString();
		}

		return (
			<StyledImage
				src={imageURL}
				alt={props?.alt ?? ""}
				loading="lazy"
				height={props?.height}
				width={props?.width}
			/>
		);
	},
	table: ({ children }) => (
		<>
			<StyledTableWrapper>
				<StyledTable>{children}</StyledTable>
			</StyledTableWrapper>
			<Spacer spacing="m" />
		</>
	),
	tr: ({ children }) => <StyledTr>{children}</StyledTr>,
	th: ({ children, style }: { children: ReactNode[]; style?: CSSProperties }) => (
		<StyledTh style={style}>{children}</StyledTh>
	),
	td: ({ children, style }: { children: ReactNode[]; style?: CSSProperties }) => (
		<StyledTd style={style}>{children}</StyledTd>
	),
	blockquote: ({ children }) => (
		<StyledBlockquote>
			<StyledIcon icon="tip" />
			<Typography>{children}</Typography>
		</StyledBlockquote>
	),
};
const blogReactMDComponents = {
	...reactMDComponents,
	h2: ({ children }) => (
		<Row>
			<Column l={6}>
				<Typography component="h2" variant={TypographyVariant.headlineSerifLG}>
					{children}
				</Typography>
			</Column>
		</Row>
	),
};

export const Md = ({ source, components = reactMDComponents }: MdProps) => (
	<ReactMarkdown
		// @ts-expect-error - https://github.com/remarkjs/react-markdown/issues/765#issuecomment-1701511651
		rehypePlugins={[RehypeRaw]}
		remarkPlugins={[RemarkGfm]}
		disallowedElements={["iframe", "embed", "video", "form", "script"]}
		components={components}
	>
		{source}
	</ReactMarkdown>
);

export const Markdown = ({
	description,
	navigationItem,
	centerButton,
}: MarkdownProps & { centerButton?: boolean }) => (
	<>
		<Md source={description} />
		{Boolean(navigationItem) && (
			<StyledButtonLinkContainer centerButton={centerButton}>
				<ActionButtonLink navigationItem={navigationItem} />
			</StyledButtonLinkContainer>
		)}
	</>
);

export const BlogMarkdown = ({
	description,
	navigationItem,
	centerButton,
}: MarkdownProps & { centerButton?: boolean }) => (
	<>
		<Md source={description} components={blogReactMDComponents} />
		{Boolean(navigationItem) && (
			<StyledButtonLinkContainer centerButton={centerButton}>
				<ActionButtonLink navigationItem={navigationItem} />
			</StyledButtonLinkContainer>
		)}
	</>
);

// Used, for example, in json+ld content where we only want plain html
export const RawMarkdown = ({ source }: Pick<MdProps, "source">) => (
	<ReactMarkdown allowedElements={["p", "a", "b", "i", "em", "strong", "ul", "li", "br"]}>
		{source}
	</ReactMarkdown>
);
