import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FONT_WEIGHT } from "@/theme";
import type { PropsWithTheme } from "@/theme";

export const StyledHeaderLink = styled("a")<PropsWithTheme<HTMLAnchorElement>>`
	position: relative;
	display: inline-flex;
	color: currentColor;
	font-size: inherit;
	font-weight: ${FONT_WEIGHT.light};
	line-height: 28px;
	margin: 0;
	padding: var(--spacing-xxs) var(--spacing-xs) var(--spacing-xxs) var(--spacing-m);
	text-decoration: none;

	&::before {
		content: normal;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 4px;
		width: 3px;
		border-radius: 1px;
		pointer-events: none;
	}

	${({ theme: { mq, palette } }) => css`
		@media ${mq.l} {
			padding: 0 var(--spacing-s);
			&:hover {
				&::before {
					content: "";
					background: ${palette.freeze[1000]};
				}
			}
		}
	`}
`;

export const HeaderLink: React.FC<PropsWithTheme<HTMLAnchorElement>> = ({ children, ...props }) => (
	<StyledHeaderLink {...props}>{children}</StyledHeaderLink>
);
