import React from "react";

import { useTranslation } from "next-i18next";

import { Hidden } from "@/components/grid";
import type { HeaderProps } from "@/components/layout/types";
import { RegionSelectorHeader } from "@/components/region-selector/header";
import { Typography } from "@/components/typography/typography";
import { Icon } from "@/design-system/atoms/icons";
import { FONT_WEIGHT } from "@/theme";

import { Header } from "../header";
import { Menu } from "../menu";
import { Toggle } from "../sidebar/toggle";

import { CallLink, EvernestHeaderContent, ExpandableNav, NavLink } from "./components";

export const EvernestHeader: React.FC<HeaderProps> = ({
	open,
	toggleOpen,
	translucentHeader,
	data,
}) => {
	const { t } = useTranslation("navigation");
	const navigationItems = data.headerNavigation.items[0]?.itemsCollection.items ?? [];
	const [businessInfoItems] = data.businessInfoCollection.items ?? [];

	return (
		<>
			{translucentHeader && (
				<Hidden s m>
					<Header fixed translucent fadeOffset={50}>
						<EvernestHeaderContent />
						{businessInfoItems?.headerCallLink && (
							<CallLink href={`tel:${businessInfoItems.headerCallLink}`}>
								<Icon icon="phone" />
							</CallLink>
						)}
					</Header>
				</Hidden>
			)}
			<Hidden l s={!translucentHeader} m={!translucentHeader}>
				<Header fixed>
					<EvernestHeaderContent>
						<Hidden l>
							<Menu aria-label={t("navigation:header-navigation")}>
								{navigationItems.filter(Boolean).map(menuItem => (
									<ExpandableNav key={menuItem.sys.id} menuItem={menuItem} />
								))}
								{businessInfoItems?.headerPhoneNumber &&
									businessInfoItems?.headerCallLink && (
										<NavLink href={`tel:${businessInfoItems.headerCallLink}`}>
											<Typography
												tight
												style={{ fontSize: "14px" }}
												weight={FONT_WEIGHT.medium}
											>
												{businessInfoItems.headerPhoneNumber}
											</Typography>
										</NavLink>
									)}
								<RegionSelectorHeader />
							</Menu>
						</Hidden>
					</EvernestHeaderContent>
				</Header>
			</Hidden>
			<Hidden s m>
				<Toggle open={open} onClick={toggleOpen} />
			</Hidden>
		</>
	);
};
