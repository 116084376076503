import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Icon } from "@/design-system/atoms/icons";
import type { PropsWithTheme } from "@/theme";
import { FONT_WEIGHT } from "@/theme";

import { Typography } from "../typography/typography";

export const Link = styled.a<PropsWithTheme<HTMLAnchorElement>>`
	${({ theme: { palette } }) => css`
		color: ${palette.blue[500]};
	`};
`;

export const StyledImage = styled.img`
	width: 100%;
	margin: var(--spacing-xs) auto;
`;

export const StyledBlockquote = styled.div`
	padding: 8px 24px;
	border-radius: 10px;
	background-color: #f8f9fd;
`;

export const StyledIcon = styled(Icon)`
	margin: 16px 4px 0 0;
	float: left;
`;

export const StyledButtonLinkContainer = styled.div<{ centerButton?: boolean }>`
	display: flex;
	margin: var(--spacing-xs) 0 16px;
	${({ centerButton }) => css`
		justify-content: ${centerButton ? "center" : "flex-start"};
	`};
`;

export const StyledMarkdownTypography = styled(Typography)`
	strong {
		font-weight: ${FONT_WEIGHT.medium};
	}
`;
