import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Trans, useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Typography } from "@/components/typography/typography";
import { Icon } from "@/design-system/atoms/icons";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";

const StyledRegionSelector = styled.div`
	margin: var(--spacing-xs) 0;
	padding: 0 var(--spacing-xs);
`;

const StyledTypography = styled(Typography)`
	display: flex;
	align-items: center;
	gap: calc(var(--spacing-xxxs) / 2);
	margin-bottom: calc(var(--spacing-xxxs) / 2);
`;

const StyledIcon = styled(Icon)`
	position: relative;
	top: -2px;
`;

const StyledLink = styled.a<PropsWithTheme>`
	text-decoration: none;
	${({ theme: { palette } }) => css`
		span {
			color: ${palette.blue[500]};
		}
	`};
`;

export const RegionSelectorSidebar: React.FC = () => {
	const { locale } = useRouter();
	const { t } = useTranslation(["common"]);

	return (
		<StyledRegionSelector>
			<StyledTypography tight>
				<StyledIcon aria-hidden="true" icon="world" />
				{locale === "de" && t("navigation:region-selector:germany")}
				{locale === "es" && t("navigation:region-selector:spain")}
			</StyledTypography>
			<Link passHref href="/" as="/" locale={locale === "de" ? "es" : "de"}>
				<StyledLink>
					<Typography variant={TypographyVariant.bodySM} weight={FONT_WEIGHT.light}>
						<Trans
							i18nKey="navigation:region-selector:switch"
							components={{ span: <span /> }}
						/>
					</Typography>
				</StyledLink>
			</Link>
		</StyledRegionSelector>
	);
};
