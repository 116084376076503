import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const Page = styled.div<PropsWithTheme>`
	min-height: 100vh;
	${({ theme: { mq, layout } }) => css`
		--header-height: ${layout.header.height}px;
		--header-height-ref: var(--header-height);
		@media ${mq.m} {
			--header-height: ${layout.header.height}px;
		}
		@media ${mq.l} {
			--header-height: ${layout.header.height}px;
		}
	`};
`;
