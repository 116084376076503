import React from "react";

import { useTranslation } from "next-i18next";

import { Hidden } from "@/components/grid";
import type { NavigationComponentProps} from "@/components/layout/types";
import { NavigationType } from "@/components/layout/types";
import { RegionSelectorSidebar } from "@/components/region-selector/sidebar";
import { EvernestLogo } from "@/design-system/atoms/evernest-logo";
import type { NavigationItem } from "@/types/contentful-api";

import { Spacer } from "../components";
import { Header } from "../header";
import { ExpandableNav } from "../main/components";
import { Menu, MenuDirection } from "../menu";

import { HeaderContent, Separator, StyledSidebar } from "./styles";
import { Anchor } from "./types";

export const EvernestSidebar: React.FC<NavigationComponentProps> = ({ open, toggleOpen, data }) => {
	const { t } = useTranslation("navigation");
	const navigationItems = data.headerNavigation.items[0]?.itemsCollection.items ?? [];

	return (
		<Hidden s m>
			<StyledSidebar anchor={Anchor.left} open={open} onClose={toggleOpen}>
				<Header plain sticky>
					<HeaderContent>
						<EvernestLogo />
					</HeaderContent>
					<Separator />
				</Header>
				<Spacer spacing="xs" />
				<Menu
					aria-label={t("navigation:header-navigation")}
					direction={MenuDirection.vertical}
				>
					{navigationItems.filter(Boolean).map((menuItem: NavigationItem) => (
						<ExpandableNav
							key={menuItem.sys.id}
							menuItem={menuItem}
							type={NavigationType.sidebar}
						/>
					))}
				</Menu>
				<RegionSelectorSidebar />
			</StyledSidebar>
		</Hidden>
	);
};
